import { assetUrl } from "../utils/asset-url";
import envLocal from "../assets/env.json";
import constants from "../constants/constants.json";

export const getEnv = async () => {
  const hostname = window.location.hostname;
  if (/^.*localhost.*/.test(hostname) || !hostname.includes("visops")) {
    return envLocal;
  }
  const response = await fetch(assetUrl("env.json"));
  return await response.json();
};

const getNonApigeeHost = () => {
  const hostname = window.location.hostname;
  if (hostname === "visops.7-eleven.com") {
    return "api.7ep.7-eleven.com";
  } else if (hostname === "visops-test.7-eleven.com") {
    return "api.7ep-test.7-eleven.com";
  } else if (hostname === "visops-dev.7-eleven.com") {
    return "api.7ep-dev.7-eleven.com";
  }
  throw new Error("Unknown hostname for non-Apigee host");
};

export const formServiceUrl = async (
  serviceName: string,
  useApigee?: boolean,
  environment?: "dev" | "test"
) => {
  const env = await getEnv();
  const hostname = window.location.origin;
  const isLocal = hostname.includes("localhost");

  let apiHost;
  let serviceUrl = `${constants.urlConstants.servicePrefix}${serviceName}`;
  let protocol = `${constants.urlConstants.protocol}`;

  if (isLocal && !constants.urlConstants.storeLocationMasterSvc.useLocal) {
    const envPrefix = environment || "dev";
    const domain = "7-eleven.com";
    apiHost = useApigee
      ? `7ep-${envPrefix}-api.${domain}`
      : `api.7ep-${envPrefix}.${domain}`;
  } else if (
    isLocal &&
    constants.urlConstants.storeLocationMasterSvc.useLocal
  ) {
    apiHost = `localhost`;
    serviceUrl = `:${constants.urlConstants.storeLocationMasterSvc.localPort}`;
    protocol = `http`;
  } else {
    apiHost =
      useApigee !== false ? env.VISOPS_SECRET_apigee_host : getNonApigeeHost();
  }

  const url = new URL(`${protocol}://${apiHost}${serviceUrl}`);
  return url.toString();
};
