import { Theme, ThemeOptions, createTheme } from "@mui/material";

const defaultButtonSize = "medium" as "small" | "medium" | "large";

export const baseTheme = createTheme({
  palette: {
    primary: {
      main: "hsl(120,69%,28%)",
    },
    secondary: {
      main: "hsl(210, 10%, 50%)",
    },
    info: {
      main: "hsl(208, 100%, 40%)",
    },
    success: {
      main: "hsla(165, 100%, 25%, 1)",
      contrastText: "#fff",
    },
    warning: {
      main: "hsla(21, 84%, 55%, 1)",
      contrastText: "#fff",
    },
    error: {
      main: "hsla(0, 73%, 41%, 1)",
      contrastText: "#fff",
    },
    divider: "#ddd",
  },
  typography: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    h5: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
});

export const defaultTableTheme = {
  mrtTheme: (theme) => ({
    baseBackgroundColor: "#f5f5f5",
    menuBackgroundColor: "#f5f5f5",
    selectedRowBackgroundColor: "hsla(0,0%,0%,0)",
  }),
  muiTableHeadCellProps: {
    sx: (theme: any) => ({
      backgroundColor: "#f5f5f5",
      color: "#000",
      // color: "hsl(120,69%,28%)",
      fontWeight: "bold",
      "& .Mui-TableHeadCell-Content-Actions": {
        maxWidth: "20px",
        overflow: "hidden",
        transition: "max-width 0.3s ease, visibility 0.3s ease",
        position: "relative",
        "&::before": {
          content: '"\\22EE"',
          position: "absolute",
          left: "5px",
          top: "50%",
          transform: "translateY(-50%)",
          fontSize: "16px",
          color: "#868a87",
        },
        "& > *": {
          visibility: "hidden",
        },
        "&:hover": {
          maxWidth: "200px",
          "&::before": {
            content: '""',
          },
          "& > *": {
            visibility: "visible",
          },
        },
      },
      "& .Mui-TableHeadCell-Content-Labels span.MuiBox-root": {
        display: "none",
        transition: "display 0.3s ease",
      },
      "& .Mui-TableHeadCell-Content-Labels span.MuiBadge-root": {
        display: "none",
        transition: "display 0.3s ease",
      },
      "& .Mui-TableHeadCell-Content-Labels:hover span.MuiBox-root": {
        display: "block",
      },
      "& .Mui-TableHeadCell-Content-Labels:hover span.MuiBadge-root": {
        display: "flex",
      },
    }),
  },
  muiTableBodyProps: {
    sx: (theme: any) => ({
      "& tr:nth-of-type(odd) > td": {
        backgroundColor: "#fff",
      },
    }),
  },
};

export const themeDef: ThemeOptions = {
  ...baseTheme,
  ...defaultTableTheme,
};
