import { IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CancelIcon from "@mui/icons-material/Cancel";

type HeaderButtonProps = {
  headerButtonProps: {
    isFullscreen: boolean;
    toggleFullscreenMode: () => void;
    handleQuitDialogOpen: () => void;
  };
};

export const HeaderActionButtons: React.FC<HeaderButtonProps> = ({
  headerButtonProps: {
    isFullscreen,
    toggleFullscreenMode,
    handleQuitDialogOpen,
  },
}) => {
  const fullscreenIcon = isFullscreen ? (
    <FullscreenExitIcon fontSize="large" />
  ) : (
    <FullscreenIcon fontSize="large" />
  );

  return (
    <Box className="header-action-buttons">
      <Tooltip
        title="Toggle fullscreen"
        placement="top"
        componentsProps={{ tooltip: { sx: { fontSize: "10px" } } }}
      >
        <IconButton color="primary" onClick={toggleFullscreenMode}>
          {fullscreenIcon}
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Close store attribute bulk upload"
        placement="top"
        componentsProps={{ tooltip: { sx: { fontSize: "10px" } } }}
      >
        <IconButton color="error" onClick={handleQuitDialogOpen}>
          <CancelIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
