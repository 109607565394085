import constants from "../constants/constants.json";

export function getUserName() {
  return sessionStorage.getItem(constants.USER_NAME_KEY) || "UNKNOWN USER";
}

export function getUserId() {
  return sessionStorage.getItem(constants.USER_ID_KEY) || "UNKNOWN ID";
}

export function getUserSession() {
  return (
    sessionStorage.getItem(constants.USER_SESSION_KEY) || "UNKNOWN SESSION"
  );
}
