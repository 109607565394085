import { Alert, AlertColor, Snackbar, Typography } from "@mui/material";

export const snackbarPayloadInit = {
  showSnackbar: false,
  message: [""],
  severity: "" as AlertColor,
};

export type SnackbarPayload = {
  showSnackbar: boolean;
  message: string[];
  severity: AlertColor;
};

export type QuickSnackBarProps = {
  quickSnackBarProps: {
    snackbarPayload: SnackbarPayload;
    setSnackbarPayload: React.Dispatch<React.SetStateAction<SnackbarPayload>>;
    isFullscreen: boolean;
  };
};

export const QuickSnackBar: React.FC<QuickSnackBarProps> = ({
  quickSnackBarProps: { snackbarPayload, setSnackbarPayload, isFullscreen },
}) => {
  const handleClose = () => {
    setSnackbarPayload(snackbarPayloadInit);
  };

  const displayDuration: number =
    snackbarPayload.severity === "error" ? 5000 : 1500;

  return (
    <Snackbar
      open={snackbarPayload.showSnackbar}
      autoHideDuration={displayDuration}
      onClose={handleClose}
      className={isFullscreen ? "fullscreen-offset-reverse" : ""}
    >
      <Alert
        severity={snackbarPayload.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        <Typography variant="h6" component="div">
          {snackbarPayload.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};
