import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export type DeleteProps = {
  deleteAction: () => void;
};

export const DeleteButton: React.FC<DeleteProps> = ({ deleteAction }) => {
  return (
    <IconButton color="inherit" onClick={deleteAction}>
      <DeleteIcon fontSize="inherit" />
    </IconButton>
  );
};
