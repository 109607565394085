import readXlsxFile from "read-excel-file";
import {
  FILE_SIZE_LIMIT,
  Message,
  RECORD_COUNT_LIMIT,
} from "../constants/ConstantValues";
import { IStoreAttributes } from "../models/IStoreAttributes";
import { UploadedFileInfo } from "./PreviewValidationService";
import { XlsxSchema } from "../models/XlsxSchema";

const columnsMap = {
  importcode: "Store ID",
  marketMoveTag: "Market Move Tag",
  strategyTestTag: "Strategy Test Tag",
  ownSiteTag: "Own Site Tag",
};

export function validateFileSize(
  acceptedFile: File,
  uploadedFileInfo: UploadedFileInfo
) {
  const filesize = acceptedFile.size / 1024;
  if (filesize > FILE_SIZE_LIMIT) {
    const errorValues: string[] = [(filesize / 1024).toFixed(2)];
    const message = getMessageToDisplay(Message.FILE_TOO_BIG, errorValues);
    updateError(message, uploadedFileInfo);
  }
}

export function validateColumns(
  records: any,
  uploadedFileInfo: UploadedFileInfo
) {
  const currentRecords = records?.length;
  if (currentRecords !== 0) {
    const headers = records[0];
    const missingColumns: string[] = findMissingKeys(headers);
    const missingColumnsMessage = getMessageToDisplay(Message.MISSING_COLUMNS, [
      missingColumns,
    ]);
    const message =
      missingColumns.length === 0 ? Message.NO_MESSAGE : missingColumnsMessage;
    updateError(message, uploadedFileInfo);
  }
}

export function validateFileRecordCount(
  records: any,
  uploadedFileInfo: UploadedFileInfo
) {
  const currentRecords = records?.length;
  let message = "";
  const headers = Object.values(columnsMap);
  if (currentRecords === 0)
    message = getMessageToDisplay(Message.INVALID_COLUMNS, [
      currentRecords,
      headers,
    ]);
  if (currentRecords > RECORD_COUNT_LIMIT)
    message = getMessageToDisplay(Message.TOO_MANY_RECORDS, [currentRecords]);
  updateError(message, uploadedFileInfo);
}

export function checkAndAddExcelRecordErrors(
  errorsFromExcel: any,
  uploadedFileInfo: UploadedFileInfo
) {
  errorsFromExcel?.forEach((errorRecord) => {
    const errorValues: string[] = [
      errorRecord.column,
      errorRecord.error,
      errorRecord.row,
    ];
    const message = getMessageToDisplay(Message.EXCEL_ROW_ERROR, errorValues);
    updateError(message, uploadedFileInfo);
  });
}

export function validateNoDuplicates(
  records: any,
  uploadedFileInfo: UploadedFileInfo
) {
  const currentRecords = records?.length;
  if (currentRecords !== 0) {
    const duplicateStoreIds: Map<string, number> =
      findDuplicateStoreId(records);

    duplicateStoreIds.forEach((count, storeId) => {
      const errorValues: string[] = [storeId, `${count}`];
      const duplicateErrorMessage = getMessageToDisplay(
        Message.DUPLICATE_STOREID_IN_EXCEL,
        errorValues
      );
      const message =
        duplicateStoreIds.size === 0
          ? Message.NO_MESSAGE
          : duplicateErrorMessage;
      updateError(message, uploadedFileInfo);
    });
  }
}

export function updateError(
  message: string,
  uploadedFileInfo: UploadedFileInfo
) {
  if (message.length > 0) {
    uploadedFileInfo.isValid = false;
    uploadedFileInfo.error.push(String(message));
  }
}

export function findMissingKeys(record: IStoreAttributes): string[] {
  const missingKeys: string[] = [];
  for (const key in columnsMap) {
    if (record[key] === undefined) {
      missingKeys.push(columnsMap[key]);
    }
  }
  return missingKeys;
}

export function findDuplicateStoreId(records: any): Map<string, number> {
  const storeIdMap = new Map<string, number>();
  records?.forEach(({ importcode }) => {
    if (importcode !== undefined)
      storeIdMap.set(importcode, (storeIdMap.get(importcode) || 0) + 1);
  });
  const duplicatesMap = new Map<string, number>(
    Array.from(storeIdMap).filter(([_, count]) => count > 1)
  );

  return duplicatesMap;
}

export function getMessageToDisplay(
  message: string,
  values: (string | string[])[]
): string {
  return message.replace(/{(\d+)}/g, (_, index) => {
    const value = values[Number(index)];
    // If the value is an array, join its elements with a separator, e.g., ', '
    return Array.isArray(value) ? value.join(", ") : value || `{${index}}`;
  });
}

export async function getExportFromExcel(
  acceptedFile: File,
  schema: XlsxSchema
) {
  try {
    const { rows, errors } = await readXlsxFile(acceptedFile, {
      transformData: (rows) => rows.slice(0),
      schema: schema,
      schemaPropertyValueForMissingColumn: undefined,
      schemaPropertyValueForEmptyCell: null,
    });
    return { rows, errors };
  } catch (error) {
    console.error("Error reading Excel file:", error);
    return { rows: [], errors: [error] };
  }
}

export function convertToCsvReadyData(rowData, headersAccessorKeyMap) {
  return rowData.map((obj) => {
    const transformedObj = {};
    for (let key in obj) {
      if (headersAccessorKeyMap.has(key) && obj.hasOwnProperty(key)) {
        const value = key.includes("Tag")
          ? String(obj[key]).replace(/\s+/g, "").trim()
          : String(obj[key]).trim();
        key = headersAccessorKeyMap.get(key);
        if (typeof value === "object" && value !== null) {
          transformedObj[key] = JSON.stringify(value);
        } else {
          transformedObj[key] =
            value.startsWith("0") || value.endsWith("0")
              ? `="${value}"`
              : `${value}`;
        }
      }
    }
    return transformedObj;
  });
}

export function downloadTemplate(downloadFilename: string, excelBlob: Blob) {
  if (excelBlob) {
    let link = document.createElement("a");
    const url = window.URL.createObjectURL(excelBlob);
    link.href = url;
    link.setAttribute("type", "hidden");
    link.download = downloadFilename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
