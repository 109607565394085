import { Typography } from "@mui/material";
import {
  MRT_RowSelectionState,
  MRT_TableState,
  useMaterialReactTable,
} from "material-react-table";
import { defaultTableTheme } from "../theme";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { IStoreAttributes } from "../models/IStoreAttributes";
import { previewColumnDef } from "./StoreAttributesColumnsDef";

export const PreviewTable = (
  isFullscreen: boolean,
  previewData: IStoreAttributes[],
  included
) => {
  const [rowSelection, setRowSelection] =
    useState<MRT_RowSelectionState>(included);
  const muiTableContainerCSSSettings = ({ table }) => ({
    sx: {
      maxHeight: isFullscreen
        ? "calc(100vh - 420px) !important"
        : "calc(100vh - 520px)",
      minHeight: isFullscreen
        ? "calc(100vh - 420px) !important"
        : "calc(100vh - 520px)",
    },
  });

  function handleToggle(selectedRow: any): void {
    console.debug("visops-store-attributes-upload: included", selectedRow);
  }

  const table = useMaterialReactTable<IStoreAttributes>({
    columns: previewColumnDef,
    data: previewData || [],
    enableBottomToolbar: false,
    enableColumnPinning: true,
    enableRowNumbers: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: "none",
    enableDensityToggle: false,
    enablePagination: false,
    enableRowSelection: false,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    enableMultiSort: true,
    enableRowVirtualization: true,
    enableGlobalFilter: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableFacetedValues: true,
    enableCellActions: false,
    enableHiding: true,
    layoutMode: "grid",
    renderTopToolbarCustomActions: () => (
      <Typography
        color="primary"
        variant="h6"
        component="h6"
        sx={{ marginLeft: "2px" }}
      >
        {" "}
        {`Total ${table.getSelectedRowModel().rows.length} stores`}{" "}
      </Typography>
    ),
    getRowId: (row) => row.importcode,
    initialState: {
      density: "compact",
      showColumnFilters: false,
      sorting: [{ id: "recordId", desc: false }],
      columnVisibility: { recordId: false },
    },
    muiSelectAllCheckboxProps: {
      icon: <RadioButtonUncheckedIcon />,
      checkedIcon: <CheckCircleOutlineIcon />,
    },
    muiSelectCheckboxProps: {
      icon: <RadioButtonUncheckedIcon />,
      checkedIcon: <CheckCircleOutlineIcon />,
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => handleToggle(row.original),
      sx: { cursor: "pointer", height: 18, padding: -0.5 },
    }),
    muiTableContainerProps: muiTableContainerCSSSettings,
    ...defaultTableTheme,
  });
  const selectecRows = table.getSelectedRowModel();

  return { table, rowSelection, selectecRows };
};
