// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --tag-font-size: 0.85rem;
}

.mrt-tag-cell-container {
  display: flex;
  width: 100vh;
  overflow: hidden;
  align-items: center;
}

.mrt-tag-cell {
  display: flex;
  box-sizing: content-box;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: center;
}

.mrt-tag {
  display: flex;
  border-radius: 1rem;
  padding: 1px 5px 1px 5px;
  font-size: var(--tag-font-size);
  height: 12px;
  justify-content: space-between;
  margin-right: 2px;
  align-items: center;
}
.marketMoveTag {
  border: 0.5px solid hsl(165, 100%, 25%);
  color: hsl(165, 100%, 25%);
}

.strategyTestTag {
  border: 0.5px solid hsl(21, 84%, 55%);
  color: hsl(21, 84%, 55%);
}

.ownSiteTag {
  border: 0.5px solid hsl(355, 78%, 53%);
  color: hsl(355, 78%, 53%);
}
.tag-icon {
  font-size: var(--tag-font-size);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.2rem;
}
`, "",{"version":3,"sources":["webpack://./src/mrtTable/MRTCustomCells.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,wBAAwB;EACxB,+BAA+B;EAC/B,YAAY;EACZ,8BAA8B;EAC9B,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,uCAAuC;EACvC,0BAA0B;AAC5B;;AAEA;EACE,qCAAqC;EACrC,wBAAwB;AAC1B;;AAEA;EACE,sCAAsC;EACtC,yBAAyB;AAC3B;AACA;EACE,+BAA+B;EAC/B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[":root {\n  --tag-font-size: 0.85rem;\n}\n\n.mrt-tag-cell-container {\n  display: flex;\n  width: 100vh;\n  overflow: hidden;\n  align-items: center;\n}\n\n.mrt-tag-cell {\n  display: flex;\n  box-sizing: content-box;\n  position: relative;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  align-items: center;\n}\n\n.mrt-tag {\n  display: flex;\n  border-radius: 1rem;\n  padding: 1px 5px 1px 5px;\n  font-size: var(--tag-font-size);\n  height: 12px;\n  justify-content: space-between;\n  margin-right: 2px;\n  align-items: center;\n}\n.marketMoveTag {\n  border: 0.5px solid hsl(165, 100%, 25%);\n  color: hsl(165, 100%, 25%);\n}\n\n.strategyTestTag {\n  border: 0.5px solid hsl(21, 84%, 55%);\n  color: hsl(21, 84%, 55%);\n}\n\n.ownSiteTag {\n  border: 0.5px solid hsl(355, 78%, 53%);\n  color: hsl(355, 78%, 53%);\n}\n.tag-icon {\n  font-size: var(--tag-font-size);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 0.2rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
