import { Tooltip } from "@mui/material";
import { useEffect, useRef } from "react";
import { DELIMITER } from "../constants/ConstantValues";
import "./MRTCustomCells.css";
import SellIcon from "@mui/icons-material/Sell";

export const tagCell = ({ cell }) => {
  const tagChildRef = useRef(null);
  useEffect(() => {
    const child = tagChildRef.current;
    child.style.marginTop = child.offsetHeight - child.clientHeight + "px";
  }, []);
  return (
    <div className="mrt-tag-cell-container">
      <div className="mrt-tag-cell" ref={tagChildRef}>
        {cell?.getValue()?.length > 0 &&
          cell
            ?.getValue()
            ?.toString()
            .split(DELIMITER)
            .map((tag, index) => (
              <span className={`mrt-tag ${cell.column.id}`} key={index}>
                <div className="tag-icon">
                  <SellIcon fontSize="inherit" />
                </div>
                {tag.trim()}
              </span>
            ))}
      </div>
    </div>
  );
};

export const joinArrayCell = ({ cell }) => {
  const arrayCellValue = cell.getValue();
  const message = Array.isArray(arrayCellValue)
    ? arrayCellValue.join("; ")
    : "";
  return (
    <Tooltip
      title={message}
      placement="left"
      componentsProps={{ tooltip: { sx: { fontSize: "10px" } } }}
    >
      <span>{message}</span>
    </Tooltip>
  );
};
