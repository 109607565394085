import { IStoreAttributes } from "../models/IStoreAttributes";

function toStoreAttributesArrayType(records: any): IStoreAttributes[] {
  return records.map((record: any) => {
    return {
      importcode: String(record.importcode),
      seiId: String(record.seiId || ""),
      marketMoveTag: String(record.marketMoveTag || ""),
      strategyTestTag: String(record.strategyTestTag || ""),
      ownSiteTag: String(record.ownSiteTag || ""),
    } as IStoreAttributes;
  });
}

function addRecordId(previewData: IStoreAttributes[]): IStoreAttributes[] {
  return previewData.map((row, index) => ({
    ...row,
    recordId: index + 1,
  }));
}

export function addRecordIdAndGetValidatedRecords(
  records: any
): IStoreAttributes[] {
  const previewData: IStoreAttributes[] = toStoreAttributesArrayType(records);
  const validateRecords: IStoreAttributes[] = addRecordId(previewData);

  return validateRecords;
}
