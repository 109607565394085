import { Box, Button, CircularProgress, Stack, Tooltip } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import "./ActionButtons.css";
import { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";

export type ActionButtonProps = {
  actionButtonProps: {
    name: string;
    back: () => void;
    isLoading?: boolean;
    action: () => void;
    cancel?: () => void;
    downloadAction?: () => void;
    startIcon: React.ReactNode;
  };
};

export const ActionButtons: React.FC<ActionButtonProps> = ({
  actionButtonProps: {
    name,
    back,
    isLoading,
    action,
    downloadAction,
    cancel,
    startIcon,
  },
}) => {
  const [isBackDisabled, setIsBackDisabled] = useState(false);
  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const loadIcon = <CircularProgress size={24} />;

  useEffect(() => {
    action === null || action === undefined || isLoading
      ? setIsActionDisabled(true)
      : setIsActionDisabled(false);
    back === null || back === undefined || isLoading
      ? setIsBackDisabled(true)
      : setIsBackDisabled(false);
  }, [action, back]);

  return (
    <Box className="store-attributes-dialog-action">
      <Tooltip
        title="Go back to previous page"
        placement="top"
        componentsProps={{ tooltip: { sx: { fontSize: "12px" } } }}
      >
        <>
          <Button
            size="large"
            color="inherit"
            disabled={isBackDisabled}
            onClick={back}
            startIcon={<ArrowBackIosNewIcon />}
          >
            Back
          </Button>
        </>
      </Tooltip>
      <Stack direction="row" spacing={2}>
        {downloadAction && (
          <Tooltip
            title="Download Invalid entries of uploaded file"
            placement="top"
            componentsProps={{ tooltip: { sx: { fontSize: "12px" } } }}
          >
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={downloadAction}
              startIcon={<DownloadIcon />}
            >
              Extract Invalid data
            </Button>
          </Tooltip>
        )}
        <Tooltip
          title={`Perform ${name} operation and move to next page`}
          placement="top"
          componentsProps={{ tooltip: { sx: { fontSize: "12px" } } }}
        >
          <>
            <Button
              size="large"
              color="primary"
              variant="contained"
              disabled={isActionDisabled}
              onClick={action}
              startIcon={isLoading ? loadIcon : startIcon}
            >
              {name}
            </Button>
          </>
        </Tooltip>
        {cancel && (
          <Tooltip
            title="Cancel and quit from the page"
            placement="top"
            componentsProps={{ tooltip: { sx: { fontSize: "12px" } } }}
          >
            <Button
              size="large"
              color="error"
              variant="contained"
              onClick={cancel}
              startIcon={<DoNotDisturbAltIcon />}
            >
              Cancel
            </Button>
          </Tooltip>
        )}
      </Stack>
    </Box>
  );
};
