import { IValidateRequestDto } from "../models/IValidation";
import { IFinalSubmitDto } from "../models/IFinalSubmitDto";
import { bulkUploadSubmit, bulkUploadValidation } from "./StoreLocationMaster";
import { ISummary } from "../models/ISummary";

export type ApiMetaInfo = {
  message: string[];
  showSnackbar: boolean;
  type: "success" | "error" | "info" | "warning";
};

export type ValidatedResponse = {
  data: IFinalSubmitDto;
  apiInfo: ApiMetaInfo;
};

export type SubmitResponse = {
  data: ISummary;
  apiInfo: ApiMetaInfo;
};

export async function sendValidationRequest(
  payloadToValidate: IValidateRequestDto
): Promise<ValidatedResponse> {
  const apiInfo: ApiMetaInfo = {
    message: [""],
    showSnackbar: true,
    type: "info",
  };
  let response = { data: undefined, apiInfo: apiInfo };
  try {
    const res: IFinalSubmitDto = await bulkUploadValidation(payloadToValidate);
    response.data = res;
    response.apiInfo.message.push("Successfully validated !!");
    response.apiInfo.type = "success";
  } catch (e) {
    response.apiInfo.type = "error";
    response.apiInfo.message.push(`Error occured while validating ${e}`);
  }

  return response;
}

export async function sendSubmitRequest(
  payloadToPersist: IFinalSubmitDto
): Promise<SubmitResponse> {
  const apiInfo: ApiMetaInfo = {
    message: [""],
    showSnackbar: true,
    type: "info",
  };
  let response = { data: undefined, apiInfo: apiInfo };
  try {
    const res: ISummary = await bulkUploadSubmit(payloadToPersist);
    response.data = res;
    response.apiInfo.message.push("Successfully submitted !!");
    response.apiInfo.type = "success";
  } catch (e) {
    response.apiInfo.type = "error";
    response.apiInfo.message.push(`Error occured while saving ${e}`);
  }

  return response;
}
