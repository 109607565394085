// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-attributes-dialog-action {
  display: flex;
  justify-content: space-between;
  margin: 18px 0 10px 0;
}

.store-attributes-dialog-action-buttons {
  height: 36px;
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/ActionButtons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".store-attributes-dialog-action {\n  display: flex;\n  justify-content: space-between;\n  margin: 18px 0 10px 0;\n}\n\n.store-attributes-dialog-action-buttons {\n  height: 36px;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
