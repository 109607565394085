import StoreAttributesDialog from "./StoreAttributesDialog";
import { StrictMode } from "react";
import { StoreAttributesProps } from "../models/StoreAttributesProps";
import { ThemeProvider } from "@mui/material";
import { baseTheme } from "../theme";

const App: React.FC<StoreAttributesProps> = (
  customProps: StoreAttributesProps
) => {
  console.debug("visops-store-attributes-upload: props", customProps);

  const mockPropsForLocal: StoreAttributesProps = {
    currentPage: 0,
    isOpen: true,
    onClose: () => {
      console.log("visops-store-attributes-upload: close button clicked");
      customProps.onClose(); //Emit an event back to parent in local
    },
  };

  const isLocalhost: boolean = window?.location?.hostname === "localhost";
  console.log("visops-store-attributes-upload: isLocalhost", isLocalhost);

  return (
    <StrictMode>
      <ThemeProvider theme={baseTheme}>
        {isLocalhost ? (
          <StoreAttributesDialog {...mockPropsForLocal} />
        ) : (
          <StoreAttributesDialog {...customProps} />
        )}
      </ThemeProvider>
    </StrictMode>
  );
};

export default App;
