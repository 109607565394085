/**
 *  In single-spa, the assets need to be loaded from a dynamic location,
 *  instead of hard coded to `/assets`. We use webpack public path for this.
 *  See https://webpack.js.org/guides/public-path/#root
 * */

export function assetUrl(url: string): string {
  const publicPath = getAssetDomain();
  const trimmedPublicPath = publicPath.replace(/\/$/, "");
  const trimmedUrl = url.replace(/^\//, "");
  return `${trimmedPublicPath}/assets/${trimmedUrl}`;
}

const getAssetDomain = (): string => {
  const hostname = window?.location?.hostname ?? "";

  const domainMap: Record<string, string> = {
    localhost: "http://localhost:8080",
    "visops-dev.7-eleven.com": "https://api.7ep-dev.7-eleven.com",
    "visops-test.7-eleven.com": "https://api.7ep-test.7-eleven.com",
  };

  const environment = hostname.split(".")[0].replace("visops-", "") || "prod";
  const baseDomain =
    domainMap[hostname] ||
    `https://api.7ep${
      environment !== "prod" ? `-${environment}` : ""
    }.7-eleven.com`;

  const fuelsPricingAppSuffix =
    "/fuels/pricing/visops-store-attributes-upload/";

  return `${baseDomain}${fuelsPricingAppSuffix}`;
};
