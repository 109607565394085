import {
  Alert,
  AlertColor,
  AlertPropsColorOverrides,
  AlertTitle,
  Typography,
} from "@mui/material";
import { title } from "process";

export type AlertBoxProps = {
  alertBoxProps: {
    severity: AlertColor;
    action?: JSX.Element;
    title?: string[];
    error: string[];
  };
};

export const AlertBox: React.FC<AlertBoxProps> = ({
  alertBoxProps: { severity, action, title, error },
}) => {
  return (
    <Alert severity={severity} action={action}>
      {title !== undefined &&
        title.length !== 0 &&
        title.map((value, key) => (
          <AlertTitle key={key}>
            <Typography variant="h6" component="div">
              {value}
            </Typography>
          </AlertTitle>
        ))}
      <div className="upload-alert-container">
        <div className="upload-alert-errors">
          {error !== undefined &&
            error.length !== 0 &&
            error.map((value, index) => (
              <Typography variant="body1" component="span" key={index}>
                {" "}
                {value}{" "}
              </Typography>
            ))}
        </div>
      </div>
    </Alert>
  );
};
