import {
  checkAndAddExcelRecordErrors,
  validateColumns,
  validateFileRecordCount,
  validateFileSize,
  validateNoDuplicates,
} from "./utility";

export type UploadedFileInfo = {
  isFilePresent: boolean;
  isValid: boolean;
  fileName: string;
  fileSize: number;
  error: string[];
};

const uploadedFileInfo: UploadedFileInfo = {
  isFilePresent: false,
  isValid: true,
  fileName: "",
  fileSize: 0,
  error: [],
};

export function getValidationInitObject() {
  return uploadedFileInfo;
}

export function getFileName(acceptedFile: File): string {
  return acceptedFile.name;
}

export function getFileSize(acceptedFile: File): number {
  return acceptedFile.size / 1024;
}

export function runAllValidation(
  records: any,
  acceptedFile: File,
  errorsFromExcel: any
): UploadedFileInfo {
  const uploadedFileInfo: UploadedFileInfo = {
    isFilePresent: false,
    isValid: true,
    fileName: "",
    fileSize: 0,
    error: [],
  };
  if (acceptedFile === null || acceptedFile === undefined)
    return uploadedFileInfo;

  uploadedFileInfo.isFilePresent = true;
  uploadedFileInfo.fileName = acceptedFile.name;
  uploadedFileInfo.fileSize = acceptedFile.size / 1024;
  uploadedFileInfo.isValid = true;
  validateFileSize(acceptedFile, uploadedFileInfo);
  validateFileRecordCount(records, uploadedFileInfo);
  validateColumns(records, uploadedFileInfo);
  checkAndAddExcelRecordErrors(errorsFromExcel, uploadedFileInfo);
  validateNoDuplicates(records, uploadedFileInfo);

  console.debug(
    "visops-store-attributes-upload: uploadedFileInfo ",
    JSON.stringify(uploadedFileInfo)
  );
  return uploadedFileInfo;
}
