import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
} from "@mui/material";

export function DiscardChanges({
  handleClose,
  openState,
  handleStoreAttributesDialog,
}) {
  return (
    <Dialog open={openState}>
      <DialogTitle>Store Attributes Bulk Upload</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to close and discard all changes?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleStoreAttributesDialog} color="error">
          Discard
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
