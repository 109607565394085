import {
  MaterialReactTable,
  MRT_RowSelectionState,
} from "material-react-table";
import { Box, Typography } from "@mui/material";
import RuleIcon from "@mui/icons-material/Rule";
import { ActionButtons } from "../components/ActionButtons";
import { PreviewTable } from "../mrtTable/PreviewTable";
import { IAuditLog } from "../models/IAuditLog";
import { IStoreAttributes } from "../models/IStoreAttributes";
import { IValidateRequestDto } from "../models/IValidation";
import { Stack } from "@mui/system";
import { sendValidationRequest } from "../api/backendApis";
import { SnackbarPayload } from "../components/QuickSnackBar";
import { IFinalSubmitDto } from "../models/IFinalSubmitDto";

export type PreviewPageProps = {
  isFullscreen: boolean;
  direction: string;
  auditLog: IAuditLog;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  previousPage: () => void;
  nextPage: () => void;
  previewData: IStoreAttributes[];
  included: MRT_RowSelectionState;
  setIncluded: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
  setSnackbarPayload: React.Dispatch<React.SetStateAction<SnackbarPayload>>;
  setBackendValidated: React.Dispatch<React.SetStateAction<IFinalSubmitDto>>;
  handleQuitDialogOpen: () => void;
};

const PreviewPage: React.FC<PreviewPageProps> = ({
  isFullscreen,
  direction,
  auditLog,
  isLoading,
  setIsLoading,
  previousPage,
  nextPage,
  previewData,
  included,
  setIncluded,
  setSnackbarPayload,
  setBackendValidated,
  handleQuitDialogOpen,
}) => {
  const previewTable = PreviewTable(isFullscreen, previewData, included);
  const nextAction =
    previewTable.selectecRows.rows.length === 0
      ? undefined
      : validateAndMoveToNextPage;

  async function validateAndMoveToNextPage() {
    setIsLoading(true);
    setIncluded(previewTable.rowSelection);
    const payloadToValidate: IValidateRequestDto = createPayload();
    sendValidationRequest(payloadToValidate).then((response) => {
      if (response.apiInfo.type === "success") {
        const validateFinalDataReadyToSave: IFinalSubmitDto = {
          ...response.data,
          auditLog: auditLog,
        };
        setBackendValidated(validateFinalDataReadyToSave);
        nextPage();
      }
      setIsLoading(false);
      setSnackbarPayload({
        showSnackbar: response.apiInfo.showSnackbar,
        message: response.apiInfo.message,
        severity: response.apiInfo.type,
      });
    });
  }

  function createPayload(): IValidateRequestDto {
    return {
      validateRecords: previewTable.selectecRows.rows.map(
        (row) => row.original
      ),
      auditLog: auditLog,
    };
  }

  const actionButtonProps = {
    name: "Validate",
    back: previousPage,
    action: nextAction,
    isLoading,
    startIcon: <RuleIcon />,
    cancel: handleQuitDialogOpen,
  };

  return (
    <Box
      className={`flex-vertical-space-between ${
        isFullscreen ? "container-height-full" : "container-height-mid"
      }  ${direction}`}
    >
      <Typography variant="h5" component="div" className="margin-bottom-space">
        {" "}
        Preview Of The Uploaded Excel{" "}
      </Typography>
      <Box
        className={`flex-vertical-space-between ${
          isFullscreen ? "container-height-full" : "container-height-mid"
        }`}
      >
        <Stack>
          <MaterialReactTable table={previewTable.table} />
        </Stack>
      </Box>
      <ActionButtons actionButtonProps={actionButtonProps} />
    </Box>
  );
};

export default PreviewPage;
