import { MRT_ColumnDef } from "material-react-table";
import {
  containsEitherMultipleTagFilterFn,
  multiAutoCompleteFilterComponent,
} from "./AutoCompleteFilter";
import { joinArrayCell, tagCell } from "./MRTCustomCells";
import { IStoreAttributes } from "../models/IStoreAttributes";

const baseHeadersInStoreAttributeTables: MRT_ColumnDef<IStoreAttributes>[] = [
  {
    header: "Record ID",
    accessorKey: "recordId",
    visibleInShowHideMenu: false,
  },
  { header: "Store ID", accessorKey: "importcode", size: 80 },
  { header: "SEI ID", accessorKey: "seiId", size: 50 },
  {
    header: "Market Move Tag",
    size: 200,
    accessorKey: "marketMoveTag",
    Filter: multiAutoCompleteFilterComponent,
    filterFn: containsEitherMultipleTagFilterFn,
    enableFilterMatchHighlighting: false,
    Cell: tagCell,
  },
  {
    header: "Strategy Test Tag",
    size: 200,
    accessorKey: "strategyTestTag",
    Filter: multiAutoCompleteFilterComponent,
    filterFn: containsEitherMultipleTagFilterFn,
    Cell: tagCell,
  },
  {
    header: "Own Site Tag",
    size: 200,
    accessorKey: "ownSiteTag",
    Filter: multiAutoCompleteFilterComponent,
    filterFn: containsEitherMultipleTagFilterFn,
    Cell: tagCell,
  },
];

export const previewColumnDef: MRT_ColumnDef<IStoreAttributes>[] = [
  ...baseHeadersInStoreAttributeTables,
];

export const validResponseColumnDef: MRT_ColumnDef<IStoreAttributes>[] = [
  ...baseHeadersInStoreAttributeTables,
  { header: "Status", accessorKey: "status", size: 50 },
];

export const invalidResponseColumnDef: MRT_ColumnDef<IStoreAttributes>[] = [
  ...baseHeadersInStoreAttributeTables,
  { header: "Status", accessorKey: "status", size: 80 },
  {
    header: "Error Message",
    accessorKey: "errors",
    size: 200,
    Cell: joinArrayCell,
  },
];
