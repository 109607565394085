import Box from "@mui/material/Box";
import PreviewPage from "../pages/PreviewPage";
import { ProcessStepper } from "../components/ProcessStepper";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import "./StoreAttributesDialog.css";
import { UploadPage } from "../pages/UploadPage";
import { useState } from "react";
import { ValidatedPage } from "../pages/ValidatedPage";
import { SummaryPage } from "../pages/SummaryPage";
import { DiscardChanges } from "../components/DiscardChanges";
import { StoreAttributesProps } from "../models/StoreAttributesProps";
import { IStoreAttributes } from "../models/IStoreAttributes";
import { HeaderActionButtons } from "../components/HeaderActionButtons";
import {
  getValidationInitObject,
  UploadedFileInfo,
} from "../utils/PreviewValidationService";
import { IAuditLog } from "../models/IAuditLog";
import {
  getUserId,
  getUserName,
  getUserSession,
} from "../utils/session-utility";
import { IFinalSubmitDto } from "../models/IFinalSubmitDto";
import {
  QuickSnackBar,
  SnackbarPayload,
  snackbarPayloadInit,
} from "../components/QuickSnackBar";
import { ISummary } from "../models/ISummary";
import { MRT_RowSelectionState } from "material-react-table";

export default function StoreAttributesDialog(
  customProps: StoreAttributesProps
) {
  const auditLogInit: IAuditLog = {
    userName: getUserName(),
    userId: getUserId(),
    fileName: "",
    auditId: getUserSession(),
  };
  const validatedResponseDataInit: IFinalSubmitDto = {
    validRecords: [],
    invalidRecords: [],
    validationSummary: {
      totalRecords: 0,
      add: 0,
      update: 0,
      storeNotFound: 0,
      error: 0,
    },
    auditLog: undefined,
  };
  const submitSummaryInit: ISummary = {
    requestId: "",
    statusMessage: "",
    summary: {
      totalRecords: 0,
      addedRecords: 0,
      updatedRecords: 0,
      skippedRecords: 0,
    },
  };

  const [isStoreAttributesDialogOpen, setIsStoreAttributesDialogOpen] =
    useState(customProps?.isOpen);
  const [isQuitDialogOpen, setIsQuitDialogOpen] = useState(false);
  const [page, setPage] = useState(customProps?.currentPage);
  const [previewData, setPreviewData] = useState<IStoreAttributes[]>([]);
  const [included, setIncluded] = useState<MRT_RowSelectionState>({});
  const [submitSummary, setSubmitSummary] =
    useState<ISummary>(submitSummaryInit);
  const [backendValidated, setBackendValidated] = useState<IFinalSubmitDto>(
    validatedResponseDataInit
  );
  const [direction, setDirection] = useState<string>("");
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState<UploadedFileInfo>(
    getValidationInitObject()
  );
  const [auditLog, setAuditLog] = useState<IAuditLog>(auditLogInit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarPayload, setSnackbarPayload] =
    useState<SnackbarPayload>(snackbarPayloadInit);

  const handleStoreAttributesDialogClose = () => {
    setIsStoreAttributesDialogOpen(false);
    customProps.onClose();
  };

  const handleQuitDialogOpen = () =>
    page === 1 || page === 2 || (page === 0 && uploadedFileInfo.isFilePresent)
      ? setIsQuitDialogOpen(true)
      : handleStoreAttributesDialogClose();
  const handleQuitDialogClose = () => setIsQuitDialogOpen(false);

  const discardAndQuitStoreAttributes = () => {
    setIsStoreAttributesDialogOpen(false);
    setIsQuitDialogOpen(false);
    customProps?.onClose();
  };

  function previousPage() {
    setDirection("leftToRight");
    setPage((current) => current - 1);
  }

  function nextPage() {
    setDirection("rightToLeft");
    setPage((current) => current + 1);
  }
  function toggleFullscreenMode() {
    setIsFullscreen((prev) => !prev);
  }

  const uploadPageProps = {
    isFullscreen,
    direction,
    uploadedFileInfo,
    setAuditLog,
    setUploadedFileInfo,
    nextPage,
    setPreviewData,
    setIncluded,
    setSnackbarPayload,
    handleQuitDialogOpen,
  };
  const previewPageProps = {
    isFullscreen,
    direction,
    auditLog,
    isLoading,
    setIsLoading,
    previousPage,
    nextPage,
    previewData,
    included,
    setIncluded,
    setBackendValidated,
    setSnackbarPayload,
    handleQuitDialogOpen,
  };
  const validatePageProps = {
    isFullscreen,
    direction,
    isLoading,
    setIsLoading,
    previousPage,
    nextPage,
    setSubmitSummary,
    backendValidated,
    setSnackbarPayload,
    handleQuitDialogOpen,
  };
  const summaryPageProps = {
    isFullscreen,
    direction,
    close: handleStoreAttributesDialogClose,
    submitSummary,
    backendValidated,
  };
  const headerButtonProps = {
    isFullscreen,
    toggleFullscreenMode,
    handleQuitDialogOpen,
  };
  const quickSnackBarProps = {
    snackbarPayload,
    setSnackbarPayload,
    isFullscreen,
  };

  const listOfPages = {
    0: <UploadPage {...uploadPageProps} />,
    1: <PreviewPage {...previewPageProps} />,
    2: <ValidatedPage {...validatePageProps} />,
    3: <SummaryPage {...summaryPageProps} />,
  };

  function showPage(pageNumber: number) {
    return listOfPages[pageNumber];
  }

  return (
    <>
      <Dialog
        open={isStoreAttributesDialogOpen}
        className={`${isFullscreen ? "store-attributes-offset" : ""}`}
        fullScreen={isFullscreen}
        fullWidth
        maxWidth="lg"
      >
        <Box className="store-attributes-dialog-container ">
          <DialogTitle className="store-attributes-dialog-header">
            <Typography component="span" variant="h5">
              Store Attributes Bulk Upload
            </Typography>
            <HeaderActionButtons headerButtonProps={headerButtonProps} />
          </DialogTitle>
          <DialogContent>
            <Box
              className={`${
                snackbarPayload.severity === "error" ? "error-shake-anime" : ""
              }`}
            >
              <Box className="bulk-upload-stepper-container">
                <ProcessStepper pageIndex={page} />
              </Box>
              <Box className="store-attributes-dialog-content">
                {showPage(page)}
              </Box>
            </Box>
            <QuickSnackBar quickSnackBarProps={quickSnackBarProps} />
          </DialogContent>
        </Box>
      </Dialog>
      <DiscardChanges
        handleClose={handleQuitDialogClose}
        openState={isQuitDialogOpen}
        handleStoreAttributesDialog={discardAndQuitStoreAttributes}
      />
    </>
  );
}
