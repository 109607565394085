// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-attributes-summary-cards {
  display: flex;
  justify-content: space-between;
}

.store-attributes-card-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 50%;
  background-color: #f1f3f4 !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25) !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/SummaryPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;EACZ,cAAc;EACd,oCAAoC;EACpC,uDAAuD;AACzD","sourcesContent":[".store-attributes-summary-cards {\n  display: flex;\n  justify-content: space-between;\n}\n\n.store-attributes-card-summary {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  flex-grow: 1;\n  max-width: 50%;\n  background-color: #f1f3f4 !important;\n  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25) !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
