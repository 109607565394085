import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import { ActionButtons } from "../components/ActionButtons";
import "./SummaryPage.css";
import { ISummary } from "../models/ISummary";
import { IFinalSubmitDto } from "../models/IFinalSubmitDto";
import csvDownload from "json-to-csv-export";
import { invalidResponseColumnDef } from "../mrtTable/StoreAttributesColumnsDef";
import { convertToCsvReadyData } from "../utils/utility";

type SummaryPageProps = {
  isFullscreen: boolean;
  direction: string;
  close: () => void;
  backendValidated: IFinalSubmitDto;
  submitSummary: ISummary;
};

const DELIMITER = ",";

export const SummaryPage: React.FC<SummaryPageProps> = ({
  isFullscreen,
  direction,
  close,
  backendValidated: { invalidRecords },
  submitSummary: { summary },
}) => {
  const summaryHeaders = {
    addedRecords: "Added",
    updatedRecords: "Updated",
    skippedRecords: "Error/Invalid",
    totalRecords: "Total",
  };
  const actionButtonProps = {
    name: "Finish & Close",
    back: undefined,
    action: close,
    startIcon: <DoneSharpIcon />,
    downloadAction:
      invalidRecords.length === 0 ? undefined : downloadInvalidStoreData,
    cancel: undefined,
  };

  function downloadInvalidStoreData() {
    const fileNameSuffix = Date.now();
    const headersAccessorKeyMap = getInvalidTableHeaderMap();
    const dataToConvert = {
      data: convertToCsvReadyData(invalidRecords, headersAccessorKeyMap),
      filename: `INVALID_STORE_ATTRIBUTES_EXPORT${fileNameSuffix}.csv`,
      delimiter: DELIMITER,
    };
    console.debug(
      "visops-store-attributes-upload: converted data",
      JSON.stringify(dataToConvert)
    );
    csvDownload(dataToConvert);
  }

  function getInvalidTableHeaderMap(): Map<string, string> {
    const headersAccessorKeyMap = new Map<string, string>();
    invalidResponseColumnDef.map((col) =>
      headersAccessorKeyMap.set(col.accessorKey, col.header)
    );
    return headersAccessorKeyMap;
  }

  return (
    <Box
      className={`flex-vertical-space-between ${
        isFullscreen ? "container-height-full" : "container-height-mid"
      }  ${direction}`}
    >
      <Typography variant="h5" component="h5" className="margin-bottom-space">
        Summary Of The Uploaded Data
      </Typography>
      <Box
        className={`flex-vertical-space-between ${
          isFullscreen ? "container-height-full" : "container-height-mid"
        }`}
      >
        <Box></Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Card className={`store-attributes-card-summary`}>
            {Object.keys(summaryHeaders).map((key) => (
              <>
                {key === "totalRecords" && <Divider />}
                <CardContent
                  key={key}
                  className="store-attributes-summary-cards"
                >
                  <Typography variant="h3" component="div">
                    {summaryHeaders[key]}
                  </Typography>
                  <Typography variant="h3" component="div">
                    {summary[key] || "0"}
                  </Typography>
                </CardContent>
              </>
            ))}
          </Card>
        </Box>
        <Box></Box>
      </Box>
      <ActionButtons actionButtonProps={actionButtonProps} />
    </Box>
  );
};
