import {
  MRT_Column,
  MRT_Header,
  MRT_RowData,
  MRT_TableInstance,
} from "material-react-table";
import { DELIMITER, EMPTY } from "../constants/ConstantValues";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { type Row } from "@tanstack/react-table";
import React from "react";
import SellIcon from "@mui/icons-material/Sell";
import { fontSize } from "@mui/system";

export const containsEitherMultipleTagFilterFn = <TData extends MRT_RowData>(
  row: Row<TData>,
  id: string,
  filterValue: number | string
) => {
  let isValid = false;

  const tagColumnValues = row
    .getValue<number | string>(id)
    .toString()
    .toLowerCase()
    .trim();
  const filterValueArray = filterValue
    .toString()
    .toLowerCase()
    .trim()
    .split(DELIMITER);

  filterValueArray.forEach((tag) => {
    const checkEachTag = tagColumnValues.includes(tag);
    if (checkEachTag) {
      isValid = true;
    }
  });
  return isValid;
};

export function multiAutoCompleteFilterComponent(props: {
  column: MRT_Column<any, unknown>;
  header: MRT_Header<any>;
  rangeFilterIndex?: number;
  table: MRT_TableInstance<any>;
}): React.ReactNode {
  const { column, table } = props;
  const columnAccessorKey = column.columnDef.accessorKey;
  const allOptions: Set<string> = new Set();
  const tableStateSelectedFilters: Set<unknown> = new Set();
  const filterByLabel = `Filter by ${column.columnDef.header}`;
  //Table state selected column filters
  table.getState().columnFilters.map((entry) => {
    if (entry.id === columnAccessorKey) {
      entry.value
        .toString()
        .split(DELIMITER)
        .forEach((item) => tableStateSelectedFilters.add(item));
    }
  });
  //All Faceted Options from Column
  column._getFacetedUniqueValues().forEach((key, value) => {
    if (columnAccessorKey.includes("Tag")) {
      value
        ?.toString()
        .split(DELIMITER)
        .forEach((element) => {
          element.length > 0 ? allOptions.add(String(element).trim()) : "";
        });
    } else {
      value && value.length !== 0
        ? allOptions.add(String(value).trim())
        : allOptions.add(String(EMPTY));
    }
  });

  return (
    <Autocomplete
      multiple
      disablePortal
      limitTags={1}
      className="auto-complete-filters"
      size="medium"
      options={Array.from(allOptions).sort()}
      value={Array.from(tableStateSelectedFilters)}
      onChange={(event, value) => {
        const stringSearch = value.toString().replace("[", "").replace("]", "");
        console.debug(
          "visops-store-attributes-upload: stringSearch",
          stringSearch,
          "event"
        );
        column.setFilterValue(stringSearch);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              icon={
                columnAccessorKey.includes("Tag") ? (
                  <SellIcon style={{ fontSize: "1rem", padding: "1px" }} />
                ) : (
                  <></>
                )
              }
              style={{ height: "18px" }}
              variant="outlined"
              label={String(option)}
              size="small"
              color="primary"
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginLeft: -10 }} checked={selected} />
          {String(option)}
        </li>
      )}
      style={{ minWidth: 160 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={filterByLabel}
          FormHelperTextProps={{ sx: { margin: "0 0 3px" } }}
          helperText="Filter Mode: Auto Complete"
        />
      )}
    />
  );
}
