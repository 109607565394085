import constants from "../constants/constants.json";
import { IValidateRequestDto } from "../models/IValidation";
import { IFinalSubmitDto } from "../models/IFinalSubmitDto";
import { createApiClient } from "./apiClient";
import { formServiceUrl } from "./apiUrl";
import { ISummary } from "../models/ISummary";

const {
  urlConstants: {
    storeLocationMasterSvc: {
      name: SERVICE_NAME,
      validateAPI,
      storeAttributePersistAPI,
      excelTemplateDownloadAPI,
    },
  },
} = constants;

const baseUrlFactory = async () => formServiceUrl(SERVICE_NAME);
const apiClient = createApiClient(baseUrlFactory);

export function bulkUploadValidation(
  validatedPreviewData: IValidateRequestDto
): Promise<IFinalSubmitDto> {
  const url = `${validateAPI}`;
  return apiClient.post<IFinalSubmitDto>(url, validatedPreviewData);
}

export function bulkUploadSubmit(
  validatedResponseData: IFinalSubmitDto
): Promise<ISummary> {
  const url = `${storeAttributePersistAPI}`;
  return apiClient.post<ISummary>(url, validatedResponseData);
}

export async function fetchTemplateExcelFile(): Promise<Blob> {
  const url = `${excelTemplateDownloadAPI}`;
  return apiClient.get<Blob>(url, {
    responseType: "blob",
  });
}
